<template>
  <v-container>
    <v-toolbar color="secondary" rounded flat>
      <v-toolbar-title class="white--text">List of Prefixes</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer />
      <v-dialog v-model="dialog" :return-value.sync="editedItem" max-width="500px">
        <template v-slot:activator="{ on }">
          <v-btn small class="ms-2 primary" rounded v-on="on">New Prefix</v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field v-model="editedItem" label="Description"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" small outlined @click="close">Cancel</v-btn>
            <v-btn color="primary" small @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Actions</th>
          </tr>
        </thead>
        <draggable v-model="items" tag="tbody">
          <tr v-for="(item, idx) in items" :key="idx">
            <td>{{ item }}</td>
            <td>
              <v-icon small class="mr-2" @click="editItem(idx)">mdi-pencil-outline</v-icon>
              <v-icon small class="mr-2" @click="deleteItem(idx)">remove</v-icon>
            </td>
          </tr>
        </draggable>
      </template>
    </v-simple-table>
    <v-btn 
      color=primary
      class="ma-4" 
      small
      @click="prepSave"
    >
      Save
    </v-btn>
    <v-snackbar :color="snackColor" v-model="snackbar" :timeout="2000">{{ response }}</v-snackbar>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
import { settingsCollection } from "../../firebaseDb";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    draggable
  },
  computed: {
    ...mapGetters(["darkTheme"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Prefix" : "Edit Prefix";
    }
  },
  data() {
    return {
      // snackbar data
      snackbar: false,
      response: "",
      snackColor: "black",
      search: "",
      items: [],
      dialog: false,
      editedIndex: -1,
      editedItem: "",
      defaultItem: "",
    };
  },
  methods: {
    ...mapActions(["toggleTheme"]),
    printOut(item, idx) {
      console.log(idx + " " + item);
    },
    prepSave() {
      let newData = Object.assign({}, { param: this.items });
      this.updateSettings(newData);
    },
    updateSettings(data) {
      settingsCollection
        .doc("prefix")
        .set(data)
        .then(() => {
          this.snackbar = true;
          this.snackColor = "success";
          this.response = "Settings successfully updated!";
        })
        .catch(error => {
          console.log(error);
          this.snackbar = true;
          this.snackColor = "fail";
          this.response = error;
        });
    },
    deleteItem(idx) {
      this.items.splice(idx, 1);
    },
    editItem(idx) {
      this.editedIndex = idx;
      this.editedItem = this.items[idx];
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = this.defaultItem;
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        this.$set(this.items, this.editedIndex, this.editedItem);
      } else {
        this.items.push(this.editedItem);
      }
      this.close();
    }
  },
  created() {
    settingsCollection
      .doc("prefix")
      .get()
      .then(querySnapshot => {
        if (querySnapshot.exists) {
          this.items = querySnapshot.data().param;
        }
      })
      .catch(err => {
        console.log("created: " + err);
      });
  }
};
</script>