<template>
  <draggable
    v-bind="dragOptions"
    tag="VTreeview"
    class="item-container"
    :list="list"
    :value="value"
    @input="emitter"
    handle=".v-input__icon--prepend"
  >
    <div class="item-group" :key="el.id" v-for="(el,idx) in realValue">
      <div class="item">
        <v-text-field
          dense
          :label="'Tier: ' + realTier"
          placeholder="New Category"
          prepend-icon="view_headline"
          v-model="el.name"
          append-outer-icon="clear"
          @click:append-outer="removeAt(idx)"
        />
      </div>
      <template v-if="nest">
        <nested
          class="item-sub"
          :list="el.children"
          :tier="newTier"
          v-bind:nest="nest"
          @loaded="loadedNest"
        />
      </template>
    </div>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
export default {
  name: "nested",
  methods: {
    emitter(value) {
      this.$emit("input", value);
    },
    removeAt(idx) {
      this.realValue.splice(idx, 1);
    },
    loadedNest() {
      this.$emit("loaded");
    }
  },
  components: {
    draggable
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    // this.value when input = v-model
    // this.list  when input != v-model
    realValue() {
      return this.value ? this.value : this.list;
    },
    realTier() {
      return this.tier;
    },
    newTier() {
      let x = this.tier + 1;
      return x;
    }
  },
  props: {
    value: {
      required: false,
      type: Array,
      default: null
    },
    list: {
      required: false,
      type: Array,
      default: null
    },
    tier: {
      required: false,
      type: Number,
      default: 0
    },
    nest: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  created() {
    if (this.realValue.length == 0) {
      const readyHandler = () => {
        if (document.readyState == "complete") {
          this.$emit("loaded");
          document.removeEventListener("readystatechange", readyHandler);
        }
      };

      document.addEventListener("readystatechange", readyHandler);
      readyHandler(); // in case the component has been instantiated lately after loading
    }
  }
};
</script>

  
<style scoped>
.item-sub {
  margin: 0 0 0 3rem;
}
</style>