<template>
  <v-container class="my-6">
    <v-row justify="center" class="mb-2">
      <!-- Pricing Option Buttons -->
      <div class="btn-group">
        <v-btn
          v-for="pricingOption in pricingOptions"
          :key="pricingOption.value"
          :value="pricingOption.value"
          @click="togglePricing(pricingOption.value)"
          :class="['mr-3 mb-3', { 'primary': activePricingOption === pricingOption.value }]"
          tile
        >
          {{ getButtonLabel(pricingOption.label) }}
        </v-btn>
      </div>
    </v-row>

    <v-divider></v-divider>

    <!-- Loading Indicator -->
    <v-progress-linear v-if="loading" indeterminate color="primary" class="my-6"></v-progress-linear>

    <!-- Data Table -->
    <v-data-table :headers="headers" :items="activePricing" class="elevation-1 mt-6" :hide-default-footer="true">
      <template v-slot:top>
        <v-toolbar color="secondary" flat rounded>
          <v-toolbar-title class="white--text">Subscription Settings</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <!-- Add New Item Button -->
          <v-btn
            dark small :loading="loading" class="mb-2 icon-toolbar"
            @click="addNewItem"
            :disabled="isAddButtonDisabled" fab color="primary"
          >
            <v-icon>mdi-pencil-plus-outline</v-icon>
          </v-btn>

          <!-- Form Dialog for Adding/Editing -->
          <v-dialog v-model="dialog" max-width="600px" persistent outlined>
            <v-form ref="dialogForm" v-model="formValid" lazy-validation>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <!-- Subscription Term Field -->
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          label="Subscription Term"
                          v-model="editedItem.text"
                          :items="filteredPlanTerms"
                          :rules="planRules"
                          hint="Select term"
                          persistent-hint
                          filled
                          @click.stop
                        />
                      </v-col>

                      <!-- Price Field -->
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.price"
                          prefix="₱"
                          label="Price"
                          filled
                          :rules="priceRules"
                          clearable
                          @click.stop
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <!-- Card Actions (Buttons) -->
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" small outlined @click="cardClose">Cancel</v-btn>
                  <v-btn color="primary" small :disabled="!isFormValid" @click="cardSave">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>

          <!-- Delete Confirmation Dialog -->
          <v-dialog v-model="deleteDialog" persistent max-width="500px">
            <v-card outlined>
              <v-card-title class="headline">Confirm Deletion</v-card-title>
              <v-card-text>
                Are you sure you want to delete this subscription item?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" small outlined @click="cancelDelete">Cancel</v-btn>
                <v-btn color="primary" small @click="confirmDelete">Delete</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <!-- Data Table Slots -->
      <template v-slot:[`item.text`]="{ item }">
        <span class="font-weight-bold">{{ item.text }}</span>
      </template>
      <template v-slot:[`item.price`]="{ item }">
        <span class="font-weight-bold">{{ formatPrice(item.price) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil-outline</v-icon>
        <v-icon small @click="deleteItem(item)">mdi-delete-outline</v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { settingsCollection } from "../../firebaseDb";

export default {
  data() {
    return {
      deleteDialog: false,
      dialog: false,
      formValid: true,
      pricing: [],
      pricing2: [],
      pricing3: [],
      pricingOptions: [
        { label: 'Pricing', value: 'pricing' },
        { label: 'Pricing2', value: 'pricing2' },
        { label: 'Pricing3', value: 'pricing3' },
      ],
      activePricingOption: 'pricing',
      headers: [
        { text: 'Term', value: 'text', sortable: false },
        { text: 'Price (₱)', value: 'price', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedItem: {
        text: '',
        price: '',
      },
      planRules: [v => !!v || 'Subscription term is required'],
      priceRules: [
        v => !!v || 'Price is required',
        v => v > 0 || 'Price must be greater than zero',
        v => /^\d+(\.\d{1,2})?$/.test(v) || 'Invalid price format',
      ],
      originalItem: null,
      editedIndex: -1,
      planTerm: ['Monthly', 'Quarterly', 'Annually'],
      loading: false,
    };
  },
  watch: {
    formValid(newVal) {
      if (newVal && this.$refs.dialogForm) {
        this.$refs.dialogForm.validate();
      }
    },
  },
  computed: {
    ...mapGetters(['darkTheme']),
    formTitle() {
      return this.editedIndex === -1 ? 'New Pricing' : 'Edit Pricing';
    },
    activePricing() {
      return this[this.activePricingOption];
    },
    isAddButtonDisabled() {
      return this.activePricing.length >= 3;
    },
    filteredPlanTerms() {
      const usedTerms = this.activePricing.map(item => item.text);
      return this.planTerm.filter(term => !usedTerms.includes(term) || term === this.editedItem.text);
    },
    isChanged() {
      if (!this.originalItem) return false;
      const originalPrice = parseFloat(this.originalItem.price).toFixed(2);
      const editedPrice = parseFloat(this.editedItem.price).toFixed(2);
      return (
        this.editedItem.text !== this.originalItem.text ||
        editedPrice !== originalPrice
      );
    },
    isFormValid() {
      // New items
      if (this.editedIndex === -1) {
        return this.editedItem.text && this.editedItem.price && this.$refs.dialogForm.validate();
      }
      // Edited items
      return this.isChanged && this.formValid;
    },
  },
  methods: {
    ...mapActions(['toggleTheme']),
    cardClose() {
      this.editedItem = { text: '', price: '' };
      this.$refs.dialogForm.resetValidation();
      this.dialog = false;
    },
    editItem(item) {
      this.editedItem = { ...item };
      this.originalItem = { ...item };
      this.editedIndex = this.activePricing.findIndex(plan => plan === item);
      this.dialog = true;
    },
    addNewItem() {
      this.editedIndex = -1;
      this.dialog = true;
    },
    cardSave() {
      if (this.$refs.dialogForm.validate()) {
        this.editedItem.price = parseFloat(this.editedItem.price).toFixed(2);
        if (this.editedIndex !== -1) {
          this.activePricing.splice(this.editedIndex, 1, { ...this.editedItem });
        } else {
          this.activePricing.push({ ...this.editedItem });
        }
        this.saveToFirebase();
        this.fetchUpdatedData();
        this.cardClose();
      }
    },
    deleteItem(item) {
      this.editedItem = item;
      this.deleteDialog = true;
    },
    cancelDelete() {
      this.deleteDialog = false;
    },
    confirmDelete() {
      const indexToDelete = this.activePricing.findIndex(plan => plan === this.editedItem);
      if (indexToDelete !== -1) {
        this.activePricing.splice(indexToDelete, 1);
        this.saveToFirebase();
        this.fetchUpdatedData();
      }
      this.cancelDelete();
    },
    togglePricing(option) {
      this.activePricingOption = option;
    },
    getButtonLabel(label) {
      return label === 'Pricing' ? 'Tier I' : label === 'Pricing2' ? 'Tier II' : 'Tier III';
    },
    formatPrice(price) {
      return `₱${parseFloat(price).toFixed(2)}`;
    },
    saveToFirebase() {
      settingsCollection
        .doc('user')
        .update({
          pricing: this.pricing,
          pricing2: this.pricing2,
          pricing3: this.pricing3,
        })
        .then(() => {
          console.log('Data saved to Firebase successfully!');
        })
        .catch(error => {
          console.error('Error saving data to Firebase:', error);
        });
    },
    fetchUpdatedData() {
      this.loading = true;
      settingsCollection
        .doc('user')
        .get()
        .then(querySnapshot => {
          if (querySnapshot.exists) {
            const data = querySnapshot.data();
            this.pricing = data.pricing || [];
            this.pricing2 = data.pricing2 || [];
            this.pricing3 = data.pricing3 || [];
          }
          this.loading = false;
        })
        .catch(err => {
          console.log('Error fetching updated data: ', err);
          this.loading = false;
        });
    },
  },
  created() {
    this.fetchUpdatedData();
  },
};
</script>
