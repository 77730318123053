<template>
  <v-container>
    <v-container class="text-center">
      <v-dialog v-model="dialog" class="custom-form" max-width="800px">
        <template v-slot:activator="{ on }">
          <v-btn 
            color="primary"
            v-on="on">Edit Parent</v-btn>
        </template>
        <v-form ref="dialog" :class="['custom-modal', darkTheme ? 'dark-mode' : '']">
          <v-card>
            <v-container class="modal-container">
              <nested v-model="items" />
            </v-container>
            <v-card-actions>
              <v-btn 
                class="ms-2 mb-3" 
                color="primary" 
                @click="addParent"
                >Add Parent</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-btn color="primary" class="mx-2" outlined @click="addNew">Add Topic</v-btn>
      <v-btn color="primary" @click="prepSave">Save</v-btn>
    </v-container>
    <v-skeleton-loader
      v-if="loadingHeader == true"
      :loading="loadingHeader"
      tile
      type="table-thead,paragraph@10"
    ></v-skeleton-loader>
    <v-tabs 
      v-show="loadedHeader" 
      v-model="tab"
      show-arrows
      prev-icon="mdi-chevron-left"
      next-icon="mdi-chevron-right"
      :prev-class="darkTheme ? 'dark-arrow' : ''"
      :next-class="darkTheme ? 'dark-arrow' : ''"
    >
      <v-tab
        v-for="n in items"
        :key="n.id"
        color="primary"
      >
        {{ n.name }}
      </v-tab>
      <v-tab-item eager ma-8 v-for="n in items" :key="n.id">
        <v-container class="pa-4">
          <nested v-model="n.children" v-bind:nest="true" @loaded="nestedLoaded" />
        </v-container>
      </v-tab-item>
    </v-tabs>
    <v-card-text class="text-center">
      <v-btn color="primary" outlined class="mr-2" @click="addNew">Add Topic</v-btn>
      <v-btn color="primary" @click="prepSave">Save</v-btn>
    </v-card-text>
    <v-snackbar :color="snackColor" v-model="snackbar" :timeout="2000">{{ response }}</v-snackbar>
  </v-container>
</template>

<script>
import nested from "../draggable/nested";
import { settingsCollection } from "../../firebaseDb";
import { mapGetters, mapActions } from 'vuex';

export default {
  components: { nested },
  data: () => ({
    loadingHeader: true,
    loadedHeader: false,
    id: 0,
    items: [],
    length: 15,
    tab: null,
    dialog: false,
    // snackbar data
    snackbar: false,
    response: "",
    snackColor: "black",
  }),
  computed: {
    ...mapGetters(['darkTheme']),
  },
  methods: {
    ...mapActions(['toggleTheme']),
    nestedLoaded() {
      if (this.loadingHeader) {
        this.loadingHeader = false;
        this.loadedHeader = true;
      }
    },
    printOut(mutationsList, observer) {
      console.log(mutationsList);
      console.log(observer);
    },
    prepSave() {
      let newData = Object.assign({}, { param: this.items, id: this.id });
      this.updateSettings(newData);
    },
    updateSettings(data) {
      settingsCollection
        .doc("categories")
        .set(data)
        .then(() => {
          this.snackbar = true;
          this.snackColor = "success";
          this.response = "Settings successfully updated!";
        })
        .catch((error) => {
          console.log(error);
          this.snackbar = true;
          this.snackColor = "fail";
          this.response = error;
        });
    },
    addNew() {
      this.id++;
      this.items[this.tab].children.push({
        children: [],
        id: this.id,
        name: "",
      });
    },
    addParent() {
      this.id++;
      this.items.push({
        children: [],
        id: this.id,
        name: "",
      });
    },
  },
  created() {
    settingsCollection
      .doc("categories")
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.exists) {
          this.items = querySnapshot.data().param;
          this.id = querySnapshot.data().id;
        }
      })
      .catch((err) => {
        console.log("created: " + err);
      });
  },
};
</script>
