<template>
  <v-container fluid>
  <v-card elevation="0" style="min-height: 100%;" max-width="1300px" class="mx-auto">
    <v-card-title class="text-center justify-center py-6">
      <h1 class="text-h5"><strong>
        Parameters</strong>
      </h1>
    </v-card-title>

    <v-tabs
      v-model="activeTab"
      centered
      flat
      show-arrows
      prev-icon="mdi-chevron-left"
      next-icon="mdi-chevron-right"
      active-class="rounded secondary white--text"
      :prev-class="darkTheme ? 'dark-arrow' : ''"
      :next-class="darkTheme ? 'dark-arrow' : ''"
    >
      <v-tab v-for="(tab, index) in tabs" :key="index">
        {{ tab }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeTab">
      <v-tab-item>
        <SettingsPrefix />
      </v-tab-item>
      <v-tab-item>
        <SettingsReportOption />
      </v-tab-item>
      <v-tab-item>
        <SettingsParentCategory />
      </v-tab-item>
      <v-tab-item>
        <Sample />
      </v-tab-item>
      <v-tab-item>
        <SettingsSubscription />
      </v-tab-item>
      <v-tab-item>
        <!-- // terms of service -->
        <SettingsTermsofService />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</v-container>
</template>

<script>
import SettingsPrefix from "./SettingsPrefix";
import SettingsReportOption from "./SettingsReportOption";
import Sample from "./TopicsSample";
import SettingsParentCategory from "./SettingsParentCategory";
import SettingsSubscription from "./SettingsSubscriptionPlan.vue";
import SettingsTermsofService from "./SettingsTermsofService.vue";
import { mapGetters } from "vuex";

export default {
  data() { 
    return {
      activeTab: 0,
      tabs: ["Case Prefix", "Report Options", "Categories", "Preview", "Subscriptions", "Terms of Service"],
    };
  },
  components: {
    SettingsPrefix,
    SettingsReportOption,
    Sample,
    SettingsParentCategory,
    SettingsSubscription,
    SettingsTermsofService,
  },
  computed: {
    ...mapGetters(['darkTheme']),
  },
  created() {
    this.$store.dispatch("fetchSettings");
  },
};
</script>
