<template>
  <v-container :class="themeClass" fluid>
    <v-row class="d-flex flex-wrap-reverse">
      <v-col cols="12" md="8">
        <v-card class="mx-auto pa-2" max-width="800" shaped outlined elevation="3">
          <!-- Title -->
          <v-card-subtitle class="mx-6 mt-8 text-h6 text-center">Terms of Service Agreement</v-card-subtitle>
          <hr class="mx-2"/>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined small v-if="showEditor" color="primary" @click="cancelEdit">
              Cancel
            </v-btn>
            <v-btn small color="primary" class="mr-6" @click="toggleEdit">
              {{ showEditor ? 'Save' : 'Edit' }}
            </v-btn>
          </v-card-actions>
          <!-- Effective Date -->
          <v-card-subtitle 
            v-if="!showEditor" 
            class="text-subtittle-1 mx-5 mb-2"
          >
            Effective date: {{ formattedEffectiveDate }}
          </v-card-subtitle>

          <div v-show="show" class="mx-5">
            <!-- Loading Spinner -->
            <v-row justify="center" v-if="loading">
              <v-col cols="12" class="text-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </v-col>
            </v-row>

            <!-- Contents of the ToS -->
            <v-row v-else>
              <v-col>
                <v-divider v-if="!showEditor"></v-divider>
                <v-card-text text-justify v-if="!showEditor" v-html="tosContent"></v-card-text>
                <v-divider v-if="!showEditor"></v-divider>

                <!-- CKEditor for editing -->
                <v-menu v-model="menu" :close-on-content-click="false" max-width="290" v-if="showEditor">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Effective Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="date = null"
                      style="max-width: 290px;"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" :min="today" @change="menu = false"></v-date-picker>
                </v-menu>
                <ckeditor v-if="showEditor" :editor="editor" :config="editorConfig" v-model="tosContent"></ckeditor>
              </v-col>
            </v-row>
          </div>
          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small outlined v-if="showEditor" color="primary" @click="cancelEdit">
              Cancel
            </v-btn>
            <v-btn small color="primary" class="mr-6" @click="toggleEdit">
              {{ showEditor ? 'Save' : 'Edit' }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card class="pa-3" rounded>
          <p class="text-center text-h6">Terms of Service Archive</p>
          <hr/>
          <v-list two-line>
            <v-list-item-group
              v-model="selected"
            >
              <template v-for="(item, index) in termItems">
                <v-list-item 
                  :key="index" 
                  @click="onTermSelected(item)"
                  :class="{ 'primary--text': item.action === 'Active' }"
                >
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.title"></v-list-item-title>
                      <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action v-if="item.action === 'Active'">
                      <v-icon color="yellow darken-3">
                        mdi-star
                      </v-icon>
                    </v-list-item-action>
                  </template>
                </v-list-item>

                <v-divider
                  v-if="index < termItems.length - 1"
                  :key="`divider-${index}`"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { mapGetters } from "vuex";
import { termsCollection } from "../../firebaseDb";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue";
import moment from "moment";

export default {
  data() {
    return {
      show: true,
      showEditor: false,
      loading: true,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "bulletedList",
            "numberedList",
            "|",
            "blockQuote",
            "|",
            "undo",
            "redo",
          ],
        },
      },
      selected: [],
      termItems: [],
      tosContent: "",
      tosEffectiveDate: "",
      today: new Date().toISOString().substr(0, 10),
      date: moment().format("YYYY-MM-DD"),
      menu: false,
      currentDocId: null,
    };
  },
  components: {
    ckeditor: CKEditor.component,
  },
  methods: {
    toggleShow() {
      this.show = !this.show;
    },
    cancelEdit() {
      this.fetchDataFromFirestore();
      this.showEditor = false;
    },
    toggleEdit() {
      this.tosEffectiveDate = this.date;

      // If the editor is in edit mode, save the new terms
      if (this.showEditor) {
        this.saveTerms();
      }
      this.showEditor = !this.showEditor;
    },

    saveTerms() {
      this.loading = true;
      // First, set all documents to inactive
      const batch = termsCollection.firestore.batch();

      termsCollection.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          batch.update(doc.ref, { isActive: false });
        });

        // After marking all as inactive, add the new document as active
        batch.commit().then(() => {
          termsCollection
            .add({
              content: this.tosContent,
              effectiveDate: this.tosEffectiveDate,
              isActive: true,
              createdAt: moment().toISOString(),
            })
            .then(() => {
              console.log("New document created and marked as active.");
              this.fetchDataFromFirestore(); 
            })
            .catch((error) => {
              console.error("Error creating new document:", error);
            })
            .finally(() => {
              this.loading = false;
            });
        }).catch((error) => {
          console.error("Error updating documents:", error);
          this.loading = false;
        });
      }).catch((error) => {
        console.error("Error fetching documents for update:", error);
        this.loading = false;
      });
    },

    fetchDataFromFirestore() {
      this.loading = true;
      termsCollection
        .orderBy("createdAt", "desc")
        .get()
        .then((querySnapshot) => {
          this.termItems = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            title:`Effective Date: ${moment(doc.data().effectiveDate, "YYYY-MM-DD").format("MMM D, YYYY")}`,
            subtitle:`Date created: ${moment(doc.data().createdAt).format("MMM D, YYYY")}`,
            action: doc.data().isActive ? "Active" : "Inactive",
            fullContent: doc.data().content,
          }));

          if (!querySnapshot.empty) {
            const latestDoc = querySnapshot.docs[0];
            this.currentDocId = latestDoc.id;
            this.tosContent = latestDoc.data().content;
            this.tosEffectiveDate = moment(latestDoc.data().effectiveDate, "YYYY-MM-DD").format("MMM D, YYYY");
            this.date = latestDoc.data().effectiveDate;
          } else {
            console.error("No documents found!");
          }
        })
        .catch((error) => {
          console.error("Error fetching documents:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onTermSelected(item) {
      // When a term item is selected, update the main card content
      this.tosContent = item.fullContent;
      this.tosEffectiveDate = item.title.replace("Effective Date: ", "");
      this.currentDocId = item.id;
      this.date = item.title.replace("Effective Date: ", "");
    }
  },
  created() {
    this.fetchDataFromFirestore();
  },
  computed: {
    ...mapGetters(["darkTheme"]),
    themeClass() {
      return this.darkTheme ? 'dark-theme' : 'light-theme';
    },
    computedDateFormatted() {
      return this.date ? this.date : "";
    },
    showCancelBtn() {
      return this.showEditor;
    },
    formattedEffectiveDate() {
      return this.tosEffectiveDate
        ? this.tosEffectiveDate
        : "";
    },
  },
};
</script>

<style scoped>
body.light-theme {
  background-color: #FFFFFF;
  color: #000000;
}

body.dark-theme {
  background-color: #121212;
  color: #FFFFFF;
}

.v-application {
  background-color: inherit !important;
}
</style>
