<template>
  <v-container>
    <v-sheet class="pa-4 light">
      <v-text-field
        v-model="search"
        label="Search Category"
        flat
        solo-inverted
        hide-details
        clearable
        clear-icon="mdi-close-circle-outline"
      ></v-text-field>
    </v-sheet>
    <v-row>
      <v-col>
        <v-treeview
          dense
          open-all
          v-model="selection"
          :active.sync="active"
          :search="search"
          :items="items"
          activatable
          @update:active="prepSearch(items,active[0])"
        ></v-treeview>
      </v-col>
    </v-row>
    <v-breadcrumbs :items="path">
      <template v-slot:divider>
        <v-icon>mdi-forward</v-icon>
      </template>
    </v-breadcrumbs>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["settings"])
  },
  data() {
    return {
      selection: [],
      items: [],
      search: null,
      active: [],
      path: []
    };
  },
  created() {
    this.items = this.settings.find(e => {
      return e.id == "categories";
    }).param;
  },
  methods: {
    prepSearch(o, id) {
      this.path = [];
      if (id != undefined) {
        let root = o.find(element => {
          return this.searchTree(element, id);
        });
        if (root) {
          let bcData = { id: root.id, text: root.name, disabled: true };
          this.path.unshift(bcData);
        }
      }
    },
    searchTree(o, id) {
      if (id == null || id.length == 0) {
        return null;
      }
      if (o.id === id) {
        return o;
      }
      let result;
      o.children.some(element => {
        result = this.searchTree(element, id);
        if (result) {
          let bcData = { id: element.id, text: element.name, disabled: true };
          this.path.unshift(bcData);
          return result;
        }
      });
      return result;
    }
  }
};
</script>